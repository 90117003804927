import { EventModel } from '@bryntum/calendar';
import moment from 'moment';

export class ServiceRequest extends EventModel {
  static get fields() {
    return [
      { name: 'serviceRequestId', defaultValue: 0 },
      { name: 'name', dataSource: 'complaint' },
      { name: 'units', defaultValue: null },
      { name: 'contactEmail', defaultValue: null },
      { name: 'contactName', defaultValue: null },
      { name: 'comments', defaultValue: '' },
      { name: 'sourceId', defaultValue: 0 },
      { name: 'serviceDateRequested', defaultValue: null }
    ];
  }
}

ServiceRequest.idField = 'serviceRequestId';

export default {
  ref: 'grid',
  store: {
    modelClass: ServiceRequest
  },
  appendTo: 'content-container',
  minWidth: 300,
  rowHeight: 150,
  features: {
    stripe: false,
    sort: 'name'
  },

  columns: [
    {
      text: 'Service Requests',
      field: 'name',
      htmlEncode: false,
      renderer({ record }) {
        const VAMS = 'VAMS';
        const multipleUnits = record.units?.length > 1;
        const firstUnit = record.units[0];
        const fleetUnitIds = record.units.map(u => u.fleetUnitId).join(', ');
        const serviceDateRequested = `${moment(record.serviceDateRequested).format('ddd MM/DD/YYYY')}`;
        const displayDate = record.serviceDateRequested ? serviceDateRequested : '';
        const serviceOrderText = !multipleUnits && firstUnit.serviceOrderId ? `SO ID: ${firstUnit.serviceOrderId}` : '';

        const sourceBadge = /*html*/ `
        <div>
          <span class="py-1 px-2 badge badge-${record.source == VAMS ? 'info' : 'warning'}">
            ${record.source ? record.source : ''}
          </span>
        </div>`;

        return /*html*/ `
        <div class="d-flex flex-column">
          <div class="grid-date"> ${displayDate} ${serviceOrderText}</div>
          <div class="text-bold">${multipleUnits ? `Multiple Units` : record.name}</div>
          <div>
            ${fleetUnitIds ? `Fleet Unit ID: ${fleetUnitIds}<br>` : ''}
            ${!multipleUnits && firstUnit.vin ? `VIN/Serial: ${firstUnit.vin}<br>` : ''}
            ${firstUnit ? `Customer: ${firstUnit.customerDisplay}<br>` : ''}
          </div>
          ${record.source ? sourceBadge : ''}
        </div>`;
      }
    }
  ]
};
