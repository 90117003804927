<template>
  <div class="grid-container"></div>
</template>

<script>
// helper
import SchedulerService from '@/shared/services/SchedulerService';
import { appointmentFilter } from '@/vcp/config/calendarConfig';
// bryntum
import { Grid } from '@bryntum/calendar';
import gridConfig from '@/vcp/config/serviceRequestGridConfig';

// export Grid
export default {
  name: 'ServiceRequestPanel',
  computed: {
    calendar() {
      return this.$parent.calendar;
    },
    serviceRequests() {
      return this.grid.store;
    }
  },
  watch: {
    branchId() {
      this.loadServiceRequests();
    }
  },
  mounted() {
    const config = {
      ...gridConfig,
      appendTo: this.$el,
      eventStore: this.calendar.eventStore,
      listeners: {
        beforeCellEditStart: this.open
      }
    };
    this.grid = new Grid(config);
    this.loadServiceRequests();
  },
  methods: {
    async loadServiceRequests() {
      const serviceRequests = await SchedulerService.getServiceRequestsForUser();
      if (this.grid && this.grid.store) {
        await this.grid.store.removeAll();
        await this.grid.store.add(serviceRequests.data);
        this.$emit('setServiceRequests', serviceRequests.data);
      }
    },
    filter(value) {
      this.grid.store.filter({
        id: 'eventNameFilter',
        filterBy: record => appointmentFilter(record, value)
      });
    },
    open({ editorContext }) {
      this.$emit('open', editorContext.record.data);
      return false;
    }
  }
};
</script>
